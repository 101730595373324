export default function expert() {
    const anchorId = window.location.hash;

    if (anchorId && anchorId.startsWith('#comment')) {
        $(anchorId).addClass('new-comment');
    }

    window.recaptchaCallback = () => {
        $('.js-btn-recaptcha').attr('disabled', false);
    };
}
import sliders from "./sliders";
import modals from "./modals";
import controls from "./controls";
import animation from "./animation";
import programSubscription from "./programSubscription";
import expert from "./expert";

global.ww = window.innerWidth;
global.wh = window.innerHeight;

const body = document.getElementsByTagName("body")[0];

$(document).ready(() => {
  document.body.className += " ready";
  global.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  controls();
  programSubscription();
  expert();
  sliders();
  modals();
  animation();

  // function setZoom() {
  //     let wWidth = window.innerWidth
  //     if(wWidth > 1440) {
  //       wWidth = window.innerWidth
  //       const zoom = wWidth / 1441
  //       body.style.zoom = zoom.toFixed(2)
  //     } else {
  //       body.style.zoom = 1
  //     }
  // }
  function setGridHeight() {
    const hero = document.querySelector(".hero");
    const grid = document.querySelector(".hero-grid");
    if (hero) {
      grid.style.height = `${hero.clientHeight}px`;
    }
  }
  setGridHeight();

  const allResponsives = () => {
    global.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    $(window).on("resize", function () {
      // setZoom()
      setGridHeight();
    });
  };
  allResponsives();

  $(window).on("resize", function () {
    global.ww = window.innerWidth;
    global.wh = window.innerHeight;
    allResponsives();
  });
});

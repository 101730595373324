/* eslint-disable */
import "jquery.cookie";
import { select2 } from "select2";
import "./lib/transition";
import "./lib/dropdown";

import mixitup from "mixitup";
import mixitupMultifilter from "./lib/mixitup-multifilter.min";
import moment from "moment-timezone";

// import 'bootstrap';
import "bootstrap/js/dist/tooltip";
import { forEach } from "lodash";

export default function controls() {
  mixitup.use(mixitupMultifilter);

  function filterProgram() {
    let programList = document.querySelector(".main-program");
    if (programList) {
      let containerEl = document.querySelector(".main-program");
      let checkboxGroup = document.querySelector(
        ".sticky-sidebar .filter-sidebar"
      );
      let checkboxes = checkboxGroup.querySelectorAll('input[type="checkbox"]');
      let reset = checkboxGroup.querySelectorAll('button[type="reset"]');

      let noResult = document.querySelector(".program-list-noResult");

      let mixer = mixitup(containerEl, {
        controls: {
          toggleLogic: "and",
          scope: "local",
        },
        multifilter: {
          enable: true,
        },
        animation: {
          duration: 299,
          nudge: false,
          reverseOut: false,
          effects: "fade translateY(8%)",
        },
        callbacks: {
          onMixEnd: function (state) {
            if (state.hasFailed) {
              noResult.style.display = "block";
            } else {
              noResult.style.display = "none";
            }
            function setHide(element) {
              element.classList.add("hide");
            }
            function removeHide(element) {
              element.classList.remove("hide");
            }
            for (let i = 0; i < state.hide.length; i++) {
              const el = state.hide[i];
              setHide(el);
            }
            for (let i = 0; i < state.show.length; i++) {
              const el = state.show[i];
              removeHide(el);
            }
            $(".program-group").each(function () {
              let $this = $(this);
              if (
                $(this).find(".mix.hide").length === $(this).find(".mix").length
              ) {
                $this[0].classList.add("group-hide");
              } else {
                $this[0].classList.remove("group-hide");
              }
            });
          },
        },
      });
      reset.forEach((button) => {
        button.addEventListener("click", function (e) {
          mixer.filter("all");
        });
      });
    }
  }
  filterProgram();

  function filterShowroom() {
    let showroomList = document.querySelector(".main-showroom");
    if (showroomList) {
      const bigBlockSelector = 'big-block',
          smallBlockSelector = 'small-block',
          hiddenSelector = 'hidden-block',
          cellsPerPage = 12
      ;
      let selectFilter = document.querySelector(".showroom-filter .js-select2");
      let noResult = document.querySelector(".showroom-list-noResult");
      let showMoreBtn = document.querySelector(".show-more-btn");

      /**
       * Изменяет видимость кнопки "Показать ещё"
       */
      function updateShowMoreState() {
        if ($(`.showroom-list .${bigBlockSelector}.${hiddenSelector}, .showroom-list .${smallBlockSelector}.${hiddenSelector}`).length) {
          showMoreBtn.style.display = "block";
        } else {
          showMoreBtn.style.display = "none";
        }
      }

      /**
       * Вычисляет кол-во ячеек занимаемых блоком
       * @param $block
       * @returns {number}
       */
      function getCellsPerBlock($block) {
        return $block.hasClass(bigBlockSelector) ? 2 : 1;
      }

      /**
       * Т.к. mixitup при инициализации ничего не делает, сделаем это за него - скроем лишние блоки и отобразим,
       * если надо, кнопку "Показать ещё"
       */
      function initHide() {
        // Отображаем первые N блоков которые занимат {cellsPerPage} ячеек, остальные скрываем
        let visibleCellCounter = 0;
        $(`.showroom-list .${bigBlockSelector}:visible, .showroom-list .${smallBlockSelector}:visible`).each(function () {
          const $this = $(this);

          if (visibleCellCounter < cellsPerPage) {
            visibleCellCounter += getCellsPerBlock($this);
          } else {
            $this.addClass(hiddenSelector);
          }
        });

        updateShowMoreState();
      }

      let mixer2 = mixitup(".main-showroom", {
        controls: {
          toggleLogic: "and",
          scope: "local",
        },
        animation: {
          duration: 299,
          nudge: false,
          reverseOut: false,
          effects: "fade translateY(8%)",
        },
        callbacks: {
          onMixStart: function () {
            // cбрасываем селектор скрытия блока
            $(`.showroom-list .${bigBlockSelector}.${hiddenSelector}, .showroom-list .${smallBlockSelector}.${hiddenSelector}`).removeClass(hiddenSelector);
          },
          onMixEnd: function (state) {
            noResult.style.display = state.hasFailed ? "block" : "none";
            initHide(); // Отображаем первые N блоков которые занимат {cellsPerPage} ячеек, остальные скрываем
          },
        },
      });

      /**
       * Последовательно отображаем (по cellsPerPage ячеек) скрытые блоки подходящие под параметры фильтрации
       */
      showMoreBtn.addEventListener('click', function (e) {
        e.preventDefault();

        let visibleBlockCounter = 0;
        $(`.showroom-list .${bigBlockSelector}.${hiddenSelector}, .showroom-list .${smallBlockSelector}.${hiddenSelector}`).each(function () {
          const $this = $(this);

          if (visibleBlockCounter < cellsPerPage) {
            visibleBlockCounter += getCellsPerBlock($this);
            $this.removeClass(hiddenSelector);
          }
        });

        updateShowMoreState();

        return false;
      });

      $(".showroom-filter .js-select2").on("select2:select", function (e) {
        var data = e.params.data;
        mixer2.filter(data.id);
        if ($(".showroom-filter .js-select2").val() !== "") {
          $(".reset-filter-showroom").show();
        } else {
          $(".reset-filter-showroom").hide();
        }
      });
      $(".reset-filter-showroom").on("click", function () {
        $(".showroom-filter .js-select2").val(null).trigger("change");
        $(".reset-filter-showroom").hide();
        mixer2.filter("all");
      });

      initHide();
    }
  }
  setTimeout(function () {
    filterShowroom();
  }, 1000);

  $(".ui.dropdown").dropdown({
    transition: "none",
  });
  $(".ui.dropdown").unbind("touchstart");

  $(".js-select2").select2({
    width: "100%",
    minimumResultsForSearch: Infinity,
  });

  $(".program-speakers-more").on("click", function () {
    $(this).hide();
    $(this).next().slideDown(200);
  });

  let selectLang = document.querySelectorAll(".select-lang");

  selectLang.forEach((item) => {
    item.addEventListener("click", function () {
      item.classList.toggle("opened");
    });
  });

  function mmenu() {
    let mbutton = document.querySelector(".mobile-menu-toggle");
    let mlist = document.querySelector(".mobile-menu-list");
    let mmenu = document.querySelector(".mobile-menu");
    let mOverlay = document.querySelector(".menu-overlay");

    mbutton.addEventListener("click", function () {
      let isActive = mbutton.classList.toggle("active");
      if (isActive) {
        mlist.classList.add("openMenu");
        mmenu.classList.add("openMenu");
      } else {
        mlist.classList.remove("openMenu");
        mmenu.classList.remove("openMenu");
      }
    });
    mOverlay.addEventListener("click", function () {
      mbutton.classList.remove("active");
      mlist.classList.remove("openMenu");
      mmenu.classList.remove("openMenu");
    });
  }

  mmenu();

  if ($("#switchTime").length) {
    function changeTime(target) {
      moment.tz.setDefault("Europe/Moscow");
      let items = $(".program-group-time");
      let postfix = target[0].dataset.postfix;
      for (let i = 0; i < items.length; i++) {
        const element = items[i];
        let time = element.dataset.from;
        if (!target[0].checked) {
          time = `${moment
            .tz(time, "HH:mm", "Europe/Moscow")
            .format("HH:mm")} <span class="text18">${postfix}</span>`;
        } else {
          time = moment
            .tz(time, "HH:mm", "Europe/Moscow")
            .local()
            .format("HH:mm");
        }
        element.getElementsByTagName("b")[0].innerHTML = time;
      }
    }
    changeTime($("#switchTime"));
    $("#switchTime").on("change", function () {
      changeTime($(this));
    });
  }

  if ($.cookie("CookieAccept") !== "yes") {
    $("#cookieAcceptBar").addClass("waiting");
    //Assign cookie on click
    $(".cookieConfirm").on("click", function (e) {
      e.preventDefault();
      $.cookie("CookieAccept", "yes", { expires: 14 }); // cookie will expire in one day
      $("#cookieAcceptBar").fadeOut();
    });
  }

  function expertContacts() {
    let button = document.querySelector(".expert-callback-more");
    let buttonTitle = document.querySelector(".expert-callback-more-title");
    if (button) {
      let list = document.querySelector(".expert-callback-list");

      button.addEventListener("click", function () {
        list.style.display = "block";
        buttonTitle.style.display = "none";
        button.classList.add("opened");
      });
    }
  }
  expertContacts();

  function replyForm() {
    let buttons = document.querySelectorAll(".reply-btn");

    buttons.forEach((item) => {
      if (item) {
        let form = item.closest(".comment-body").querySelector(".reply-form");
        item.addEventListener("click", function (e) {
          e.preventDefault();
          form.classList.toggle("hidden");
        });
      }
    });
  }
  replyForm();

  function videoPlayer() {
    let players = document.querySelectorAll(".video-player");

    players.forEach((item) => {
      if (item) {
        let video = item.querySelector("video");
        item.addEventListener("click", function () {
          if (video.paused === true) {
            video.play();
            item.classList.add("play");
          } else {
            video.pause();
            item.classList.remove("play");
          }
        });
        video.addEventListener("ended", function () {
          item.classList.remove("play");
        });
      }
    });
  }
  videoPlayer();
}

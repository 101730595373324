/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import { ScrollScene } from "scrollscene";
import { gsap } from "gsap";

export default function animation() {
  const hero = document.querySelector(".hero");
  if (hero) {
    const timeline = gsap.timeline({ repeat: -1 });
    timeline
      .to(".hero-grid-item:nth-child(1) img:nth-child(1)", 0.5, {
        delay: 1,
        opacity: 0,
      })
      .to(
        ".hero-grid-item:nth-child(1) img:nth-child(2)",
        1,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(3)", 0.5, {
        background: "#f5f5f5",
      })
      .to(
        ".hero-grid-item:nth-child(3) img:nth-child(1)",
        0.5,
        { opacity: 0 },
        "-=0.5"
      )
      .to(
        ".hero-grid-item:nth-child(3) img:nth-child(2)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(6)", 0.5, {
        delay: 1,
        background: "#ffffff",
      })
      .to(
        ".hero-grid-item:nth-child(6) img:nth-child(1)",
        0.5,
        { opacity: 0 },
        "-=0.5"
      )
      .to(
        ".hero-grid-item:nth-child(6) img:nth-child(2)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(4) img:nth-child(1)", 0.5, { opacity: 0 })
      .to(
        ".hero-grid-item:nth-child(4) img:nth-child(2)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(2)", 0.5, {
        delay: 1,
        background: "#ffffff",
      })
      .to(
        ".hero-grid-item:nth-child(2) img:nth-child(1)",
        0.5,
        { opacity: 0 },
        "-=0.5"
      )
      .to(
        ".hero-grid-item:nth-child(2) img:nth-child(2)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(5) img:nth-child(1)", 0.5, { opacity: 0 })
      .to(
        ".hero-grid-item:nth-child(5) img:nth-child(2)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(1) img:nth-child(2)", 0.5, { opacity: 0 })
      .to(
        ".hero-grid-item:nth-child(1) img:nth-child(1)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(3)", 0.5, {
        background: "#FFFFFF",
      })
      .to(
        ".hero-grid-item:nth-child(3) img:nth-child(2)",
        0.5,
        { opacity: 0 },
        "-=0.5"
      )
      .to(
        ".hero-grid-item:nth-child(3) img:nth-child(1)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(6)", 0.5, {
        delay: 1.5,
        background: "#f5f5f5",
      })
      .to(
        ".hero-grid-item:nth-child(6) img:nth-child(2)",
        0.5,
        { opacity: 0 },
        "-=0.5"
      )
      .to(
        ".hero-grid-item:nth-child(6) img:nth-child(1)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(4) img:nth-child(2)", 0.5, { opacity: 0 })
      .to(
        ".hero-grid-item:nth-child(4) img:nth-child(1)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(2)", 0.5, {
        delay: 1.5,
        background: "#f5f5f5",
      })
      .to(
        ".hero-grid-item:nth-child(2) img:nth-child(2)",
        0.5,
        { opacity: 0 },
        "-=0.5"
      )
      .to(
        ".hero-grid-item:nth-child(2) img:nth-child(1)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      )
      .to(".hero-grid-item:nth-child(5) img:nth-child(2)", 0.5, { opacity: 0 })
      .to(
        ".hero-grid-item:nth-child(5) img:nth-child(1)",
        0.5,
        { opacity: 1 },
        "-=0.5"
      );
  }

  const standHero = document.querySelector(".stand-hero");
  if (standHero) {
    const timeline = gsap.timeline({ repeat: -1 });
    timeline
      .to(".stand-hero-grid-item:nth-child(1) a:nth-child(1)", 0.5, {
        delay: 4,
        opacity: 0,
        zIndex: 0,
      })
      .to(
        ".stand-hero-grid-item:nth-child(1) a:nth-child(2)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(2) a:nth-child(1)",
        0.5,
        { delay: 0.5, opacity: 0, zIndex: 0 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(2) a:nth-child(2)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(3) a:nth-child(1)",
        0.5,
        { delay: 0.5, opacity: 0, zIndex: 0 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(3) a:nth-child(2)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(".stand-hero-grid-item:nth-child(4) a:nth-child(1)", 0.5, {
        opacity: 0,
        zIndex: 0,
      })
      .to(
        ".stand-hero-grid-item:nth-child(4) a:nth-child(2)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(5) a:nth-child(1)",
        0.5,
        { delay: 0.5, opacity: 0, zIndex: 0 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(5) a:nth-child(2)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(".stand-hero-grid-item:nth-child(6) a:nth-child(1)", 0.5, {
        opacity: 0,
        zIndex: 0,
      })
      .to(
        ".stand-hero-grid-item:nth-child(6) a:nth-child(2)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(".stand-hero-grid-item:nth-child(1) a:nth-child(2)", 0.5, {
        delay: 4,
        opacity: 0,
        zIndex: 0,
      })
      .to(
        ".stand-hero-grid-item:nth-child(1) a:nth-child(1)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(2) a:nth-child(2)",
        0.5,
        { delay: 0.5, opacity: 0, zIndex: 0 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(2) a:nth-child(1)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(3) a:nth-child(2)",
        0.5,
        { delay: 0.5, opacity: 0, zIndex: 0 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(3) a:nth-child(1)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(".stand-hero-grid-item:nth-child(4) a:nth-child(2)", 0.5, {
        opacity: 0,
        zIndex: 0,
      })
      .to(
        ".stand-hero-grid-item:nth-child(4) a:nth-child(1)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(5) a:nth-child(2)",
        0.5,
        { delay: 0.5, opacity: 0, zIndex: 0 },
        "-=0.5"
      )
      .to(
        ".stand-hero-grid-item:nth-child(5) a:nth-child(1)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      )
      .to(".stand-hero-grid-item:nth-child(6) a:nth-child(2)", 0.5, {
        opacity: 0,
        zIndex: 0,
      })
      .to(
        ".stand-hero-grid-item:nth-child(6) a:nth-child(1)",
        0.5,
        { opacity: 1, zIndex: 1 },
        "-=0.5"
      );
  }

  const domNode = document.querySelector(".program-subscribe");
  const scrollTrigger = document.querySelector(".main-program");
  if (domNode) {
    const scrollScene = new ScrollScene({
      triggerElement: scrollTrigger,
      destroyImmediately: true,
      triggerHook: 0.5,
      duration: scrollTrigger.clientHeight - 200,
      breakpoints: { 0: true, 1200: false },
    });

    scrollScene.Scene.on("enter", function (event) {
      domNode.style.opacity = 1;
      domNode.style.visibility = "visible";
    });
    scrollScene.Scene.on("leave", function (event) {
      domNode.style.opacity = 0;
      domNode.style.visibility = "hidden";
    });
  }

  const headerAbsolute = document.querySelector(".header-absolute");
  const headerFixed = document.querySelector(".header-fixed");
  const topNavFixed = document.querySelector(".top-nav");
  const standBlock = document.querySelector(".stand-about");
  function scrollTop() {
    if (window.scrollY >= headerFixed.clientHeight) {
      headerFixed.style.opacity = 1;
      headerFixed.style.visibility = "visible";
      headerFixed.style.top = "0%";
    } else {
      headerFixed.style.opacity = 0;
      headerFixed.style.visibility = "hidden";
      headerFixed.style.top = "-100%";
    }
  }
  if (headerAbsolute) {
    scrollTop();
    window.addEventListener("scroll", function () {
      scrollTop();
    });
  } else if (headerFixed) {
    const body = document.getElementsByTagName("body")[0];
    if (!topNavFixed) {
      headerFixed.classList.add("notHide");
      body.style.paddingTop = `${headerFixed.clientHeight}px`;
      window.addEventListener("resize", function () {
        body.style.paddingTop = `${headerFixed.clientHeight}px`;
      });
      return false;
    }
    body.style.paddingTop = `0px`;
    headerFixed.classList.remove("header-fixed");
    topNavFix();
  }

  function topNavFix() {
    if (!topNavFixed) return false;
    navSticky();
    window.addEventListener("scroll", function () {
      navSticky();
    });
  }
  function navSticky() {
    const offsetNav = standBlock.offsetTop;
    if (window.scrollY >= offsetNav) {
      topNavFixed.classList.add("sticky");
    } else {
      topNavFixed.classList.remove("sticky");
    }
  }
}

export default class Storage {
    constructor(store, key) {
        this.store = store;
        this.storeKey = key;
        this.subscriptions = new Set(JSON.parse(store.getItem(key) || '[]'));
    }

    add(eventId) {
        this.subscriptions.add(eventId);
        this.save();
    }

    del(eventId) {
        this.subscriptions.delete(eventId);
        this.save();
    }

    getAll() {
        return [...this.subscriptions];
    }

    /**
     * @protected
     */
    save() {
        this.store.setItem(this.storeKey, JSON.stringify([...this.subscriptions]))
    }
}
// import slick from 'slick-carousel';
import Swiper from "swiper";

export default function sliders() {
    const mainPartners = document.querySelector(".main-partners");
    if (mainPartners) {
        const sliderPartner = new Swiper(".main-partners .swiper-container", {
            slidesPerView: "auto",
            spaceBetween:  25,
            pagination:    {
                el:        ".main-partners .swiper-pagination",
                clickable: true,
            },
        });
    }

    const speakersBigSlider = document.querySelector(
        ".swiper-speakers-slider-big"
    );
    if (speakersBigSlider) {
        const sliderPartner = new Swiper(".swiper-speakers-slider-big", {
            loop:        true,
            navigation:  {
                nextEl: ".speakers-slider-big-next",
                prevEl: ".speakers-slider-big-prev",
            },
            breakpoints: {
                320: {
                    slidesPerView: "auto",
                    spaceBetween:  12,
                },
                575: {
                    slidesPerView: "auto",
                    spaceBetween:  20,
                },
            },
        });
    }
    const speakersSmallSlider = document.querySelector(
        ".swiper-speakers-slider-small"
    );
    if (speakersSmallSlider) {
        const sliderPartner = new Swiper(".swiper-speakers-slider-small", {
            slidesPerView:  "auto",
            loop:           true,
            spaceBetween:   10,
            watchOverflow:  true,
            dynamicBullets: true,
            breakpoints:    {
                320:  {
                    spaceBetween: 8,
                    pagination:   {
                        el:             ".swiper-speakers-slider-small .swiper-pagination",
                        dynamicBullets: true,
                        clickable:      true,
                    },
                },
                1024: {
                    spaceBetween: 20,
                    pagination:   {
                        el:             ".swiper-speakers-slider-small .swiper-pagination",
                        dynamicBullets: true,
                        clickable:      true,
                    },
                },
            },
        });
    }
    const networkingSlider = document.querySelector(".swiper-networking-slider");
    if (networkingSlider) {
        const slider = new Swiper(".swiper-networking-slider", {
            slidesPerView: 3,
            spaceBetween:  10,
            watchOverflow: true,
            pagination:    {
                el:             ".swiper-pagination",
                clickable:      true,
                dynamicBullets: true
            },
            breakpoints:   {
                320:  {
                    slidesPerView: 1,
                    spaceBetween:  8
                },
                768:  {
                    slidesPerView: 2,
                    spaceBetween:  20
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween:  20
                },
            },
        });
    }

    const simpleSliders = document.querySelectorAll(".simple-slider");
    if (simpleSliders) {
        simpleSliders.forEach((slider) => {
            const lg = slider.dataset.lg || 4;
            const md = slider.dataset.md || 3;
            const sm = slider.dataset.sm || 2;
            const xs = slider.dataset.xs || 1;

            const simple = new Swiper(slider, {
                slidesPerView: xs,
                spaceBetween:  10,
                watchOverflow: true,
                breakpoints:   {
                    320:  {
                        slidesPerView: xs,
                        spaceBetween:  8,
                        pagination:    {
                            el:             ".swiper-pagination",
                            dynamicBullets: true,
                            clickable:      true,
                        },
                    },
                    565:  {
                        slidesPerView: sm,
                        spaceBetween:  8,
                        pagination:    {
                            el:             ".swiper-pagination",
                            dynamicBullets: true,
                            clickable:      true,
                        },
                    },
                    768:  {
                        slidesPerView: md,
                        spaceBetween:  20,
                        pagination:    {
                            el:             ".swiper-pagination",
                            dynamicBullets: true,
                            clickable:      true,
                        },
                    },
                    1024: {
                        slidesPerView: lg,
                        spaceBetween:  20,
                        pagination:    {
                            el:        ".swiper-pagination",
                            clickable: true,
                        },
                    },
                },
            });
            // if (slider.querySelector(".simple-slider-wrapper").children.length > lg) {
            //   simpleSliders
            //     .querySelector(".simple-slider-wrapper")
            //     .classList.add("disabled");
            //   simpleSliders
            //     .querySelector(".swiper-pagination")
            //     .classList.add("disabled");
            // }
        });
    }

    // const swiper = new Swiper(".swiper-container");

    // const fadeDuration = 2000;
    // const displayTime = 4000;
    // const currentIndex = 1;
    // const nextIndex = 1;

    // $(".lead__image rect").css({ opacity: 0.0 });
    // $(`.lead__image rect:nth-child(${nextIndex})`)
    //   .addClass("show")
    //   .animate({ opacity: 1.0 }, fadeDuration);

    // setInterval(function () {
    //   nextIndex = currentIndex + 1;
    //   if (nextIndex > $(".lead__image rect").length) {
    //     nextIndex = 1;
    //   }
    //   $(`.lead__image rect:nth-child(${nextIndex})`)
    //     .addClass("show")
    //     .animate({ opacity: 1.0 }, fadeDuration);
    //   $(`.lead__image rect:nth-child(${currentIndex})`)
    //     .animate({ opacity: 0.0 }, fadeDuration)
    //     .removeClass("show");
    //   currentIndex = nextIndex;
    // }, displayTime);
}

import "./lib/jquery.validate";
import mask from "./lib/jquery.mask";
import "magnific-popup";

export default function modals() {
  $(".js-popup").magnificPopup({
    type: "inline",
    preloader: false,
    closeBtnInside: true,
    focus: "#firstName",
    callbacks: {
      beforeOpen() {
        if ($(window).width() < 700) {
          this.st.focus = false;
        } else {
          this.st.focus = "#firstName";
        }
      },
    },
  });

  $(document).on("click", ".close-modal", function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });

  $(".zoom-gallery").magnificPopup({
    delegate: "a",
    type: "image",
    closeOnContentClick: false,
    closeBtnInside: false,
    mainClass: "mfp-with-zoom mfp-img-mobile",
    image: {
      verticalFit: true,
      titleSrc(item) {
        return `${item.el.attr("title")}`;
      },
    },
    gallery: {
      enabled: true,
      tPrev: "Назад (Стрелка влево)", // title for left button
      tNext: "Вперед (Стрелка вправо)", // title for right button
      tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
    },
    zoom: {
      enabled: true,
      duration: 300, // don't foget to change the duration also in CSS
      opener(element) {
        return element.find("img");
      },
    },
  });

  $(".inputPhone").mask("+7 (000) 000-00-00", { clearIfNotMatch: true });

  //   const langRu = {
  //     required: "Обязательное поле.",
  //     remote: "Пожалуйста, исправьте это поле.",
  //     email: "Пожалуйста, введите адрес электронной почты.",
  //     url: "Пожалуйста, введите URL.",
  //     date: "Пожалуйста, введите дату.",
  //     dateISO: "Пожалуйста, введите дату (ISO).",
  //     number: "Пожалуйста, введите номер.",
  //     digits: "Пожалуйста, введите только цифры.",
  //     equalTo: "Пожалуйста, введите то же значение снова.",
  //     maxlength: $.validator.format("Пожалуйста, введите не более {0} символов."),
  //     minlength: $.validator.format("Пожалуйста, введите не менее {0} символов."),
  //     rangelength: $.validator.format(
  //       "Пожалуйста, введите значение длиной от {0} до {1} символов."
  //     ),
  //     range: $.validator.format("Пожалуйста, введите значение между {0} и {1}."),
  //     max: $.validator.format(
  //       "Пожалуйста, введите значение, меньшее или равное {0}."
  //     ),
  //     min: $.validator.format(
  //       "Пожалуйста, введите значение, большее или равное {0}."
  //     ),
  //     step: $.validator.format("Пожалуйста, введите кратное {0}."),
  //   };
  //   $("form.validate").each(function () {
  //     const $this = $(this);
  //     $this.validate({
  //       inputPhone: {
  //         minlength: 11,
  //       },
  //       inputEmail: {
  //         email: true,
  //       },
  //       submitHandler(form) {
  //         form.submit();
  //       },
  //     });
  //   });
  //   $.validator.messages = langRu;
}

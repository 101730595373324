import Storage from "./storage";

const store = new Storage(window.localStorage, 'subscriptions');

export default function programSubscription() {
    const $formWrapper = $('.program-subscribe');
    const $formBlock = $formWrapper.find('.form-block');
    const $form = $formBlock.find('form');
    const $successBlock = $('.program-subscribe .program-sending-title');
    const hiddenClass = 'hidden';


    /**
     * Устанавливате состояние блока расписания в изначальное положение
     */
    function programFormRefresh() {
        $formBlock.removeClass(hiddenClass);
        $successBlock.addClass(hiddenClass);
    }

    /**
     * Показывает блок отправки расписания
     */
    function programFormReady() {
        programFormRefresh();
        $formWrapper.removeClass(hiddenClass);
    }

    /**
     * Скрывает блок отправки расписания
     */
    function programFormHidden() {
        programFormRefresh();
        $formWrapper.addClass(hiddenClass);
    }

    /**
     * Устанавливает состояние блока - расписание отправлено
     */
    function programFormSended() {
        $formBlock.addClass(hiddenClass);
        $successBlock.removeClass(hiddenClass);
    }

    /**
     * При клике на кнопку "Записаться" сохраняем выбор в LocalStorage
     */
    $('.program-select-input').change(function (e) {
        const $checkbox = $(e.target);
        const eventId = parseInt($checkbox.data('id'), 10);
        const isChecked = $checkbox.prop('checked')
        ;

        if (isChecked) {
            store.add(eventId)
        } else {
            store.del(eventId)
        }

        if (store.getAll().length) {
            programFormReady();
        } else {
            programFormHidden();
        }
    });

    /**
     * Проставляет состояния кнопки "Записаться" при загрузке страницы
     */
    function initSubscriptions() {
        store.getAll().forEach(id => {
            $(`#programItem${id}`).prop('checked', true);
        })
    }

    initSubscriptions();

    $form.on('submit', function (e) {
        const $startButton = $form.find('button');
        const formData = new FormData(this)
        ;

        e.preventDefault();

        $.ajax({
            url: $(this).attr('action'),
            type: $(this).attr('method'),
            data: Object.assign({'event-id': store.getAll()}, Object.fromEntries(formData)),
            dataType: 'json',
            beforeSend(xhr) {
                $startButton.prop('disabled', true);
            },
            success(response) {
                programFormSended();
            }
        }).always(function () {
            $form[0].reset();
            $startButton.prop('disabled', false);
        });
    });
}